<!--单位管理 -->
<template>
    <div>

        <el-card class="box-card">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
                <el-breadcrumb-item>单位管理</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="hr-20"></div>
            <!-- 搜索組件 -->
            <div>
                <el-form label-width="0" class="ruleForm" size="small" inline>
                    <el-form-item class="fl">
                        <el-input v-model="searchName" clearable placeholder="请输入单位名称"/>
                    </el-form-item>

                    <el-form-item class="fl">
                        <el-select v-model.trim="searchType" placeholder="请选择单位性质" clearable>
                            <el-option label="机关" value="机关"></el-option>
                            <el-option label="事业单位" value="事业单位"></el-option>
                            <el-option label="企业" value="企业"></el-option>
                            <el-option label="学校" value="学校"></el-option>
                            <el-option label="医院" value="医院"></el-option>
                            <el-option label="社会团体" value="社会团体"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item class="fl">
                        <el-select v-model="currentStatus" multiple placeholder="状态" @change="setStatus">
                            <el-option
                                v-for="item in Status"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item class="fl">
                        <el-button type="primary" plain size="mini" @click="requestQuery"><i
                            class="fa fa-search fa-fw"></i>&nbsp;查询
                        </el-button>
                        <el-button type="success" plain size="mini" @click="requestRefresh" icon="el-icon-refresh">刷新
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>

            <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" @row-dblclick="requestSee">
                <el-table-column prop="name" label="单位名称" fixed align="left" min-width="240px"></el-table-column>

                <el-table-column prop="nature" label="单位性质" align="center" min-width="160px"></el-table-column>

                <el-table-column prop="industry" label="行业" align="center" min-width="240px"></el-table-column>

                <el-table-column prop="cellphone" label="联系人电话" align="center" min-width="160px"></el-table-column>

                <el-table-column label="提交认证时间" min-width="160" align="center">
                    <template slot-scope="scope">{{ scope.row.submitTime | formatDate }}</template>
                </el-table-column>

                <el-table-column label="状态" align="center" min-width="100px">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.status===20" type="warning">已驳回</el-tag>
                        <el-tag v-else-if="scope.row.status === 21" type="success">已认证</el-tag>
                        <el-tag v-else type="info">未审核</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" min-width="200" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="primary" plain size="mini" @click="requestSee(scope.row)"
                                   v-text="scope.row.status===2?'办理':'查看'"></el-button>

                        <el-dropdown>
                            <span class="el-dropdown-link dropdown ml-2">
                                <i class="el-icon-share"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown" v-if="scope.row.account">
                                <el-dropdown-item v-if="scope.row.account.status===1" class="lid">
                                    <el-button type="danger" size="mini"
                                               plain icon="el-icon-open"
                                               @click="SetStatus(0,scope.row.account.id,scope.row)">
                                        锁定
                                    </el-button>
                                </el-dropdown-item>
                                <el-dropdown-item v-else class="lid">
                                    <el-button type="success" size="mini" plain icon="el-icon-open"
                                               @click="SetStatus(1,scope.row.account.id)">解锁</el-button>
                                </el-dropdown-item>
                                <el-dropdown-item @click="resetPass(scope.row)" class="lid">
                                    <el-button size="mini" icon="el-icon-orange" @click="resetPass(scope.row)">重置密码</el-button>
                                </el-dropdown-item>
                                <el-dropdown-item class="lid">
                                    <el-button size="mini" icon="el-icon-edit" @click="handleEdit(scope.row)">编辑角色</el-button>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>

                    </template>
                </el-table-column>
            </el-table>

            <el-pagination v-if="total" id="pagination" background layout="prev, pager, next" :total="total"
                           :page-size="pageSize"
                           :current-page="pages" @current-change="requestPages"/>
            <span style="float: left;margin: -35px 0 0 0;">共计{{ total }}条数据</span>
        </el-card>

        <el-dialog :title="title" :visible.sync="usernum" width="40%">
            <div class="hr-10"></div>
            <el-transfer v-model="rolevalue" :titles="titles" :props="{ key: 'value', label: 'desc' }" :data="roledata"
                         style="text-align: left">
            </el-transfer>
            <div class="hr-10"></div>
            <el-button @click="usernum = false">取 消</el-button>
            <el-button type="primary" plain @click="submitForm()">提交</el-button>
        </el-dialog>


        <el-dialog :visible.sync="createDialog" width="60%">
            <el-row class="box-card">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-header style="font-size: 16px"><h3>单位认证信息</h3></el-header>
                </el-breadcrumb>
                <el-row :gutter="20">
                    <el-col :span="24" class="basicCoxs">
                        <div class="basicCoxs">

                            <!--  v-if="seeData.sex != null"-->
                            <span> 单位姓名：{{ seeData.name }}</span>
                            <span> 单位性质：{{ seeData.nature }}</span>
                            <span> 所属行业：{{ seeData.industry }}</span>
                            <span> 成立时间：{{ seeData.establishTime }}</span>
                            <span> 单位法人：{{ seeData.corp }}</span>
                            <span> 员工人数：{{ seeData.staffCount }}</span>
                            <span> 状态：：{{ seeData.status }}</span>
                            <span> 所在分区：{{ seeData.subarea }}</span>
                            <span> 固定电话：{{ seeData.telephone }}</span>
                            <span> 单位类型：{{ seeData.type }}</span>
                            <span> 单位网站：{{ seeData.website }}</span>
                            <span> 邮箱：{{ seeData.email }}</span>
                            <span> 是否是高新技术：{{ seeData.highTech }}</span>
                            <span> 单位修改时间：{{ seeData.modifiedTime |formatTime }}</span>
                            <span> 注册资本：{{ seeData.registeredCapital }}</span>
                            <span> 是否是留创项目：{{ seeData.returneesEntrepreneurial }}</span>
                            <span> 组织机构代码：{{ seeData.organizationCode }}</span>
                            <span>税务局登记号：{{ seeData.irdRegistrationCode }}</span>
                            <span> 单位营业执照件号：{{ seeData.licenseCode }}</span>
                            <span> 统一社会信用代码：{{ seeData.socialCreditCode }}</span>
                        </div>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-button plain type="primary" @click="createDialog =!createDialog">确定</el-button>
                    </el-col>
                </el-row>
            </el-row>
        </el-dialog>
    </div>
</template>

<script>
//import headtitle from "@/components/ControlPanel/headtitle";
// import fksearch from "@/components/ControlPanel/fksearch";

export default {
    components: {
        //headtitle,
        // fksearch
    },
    data() {
        return {
            newarr: [],
            loading: false,
            createDialog: false,
            tableData: [],
            seeData: {},
            multipleSelection: [],
            page: 1,
            pages: 0,
            total: 10,
            pageSize: 10,
            searchName: '',
            searchType: '',
            searchIndustry: '',
            searchEstablishTime: '',
            currentStatus: [],
            Status: [{
                value: 2,
                label: '未审核'
            }, {
                value: 21,
                label: '已认证'
            }, {
                value: 20,
                label: '管理员驳回'
            }],
            usernum: false,
            titles: ['可选角色', '已选角色'],
            title: '',
            roledata: [],
            rolevalue: [],
            form: {
                id: '',
                roleIds: [],
                status: 0,
            },
        };
    },
    created() {
        this.getData(1);
    },
    methods: {
        request() {
            let newPage = this.$router.resolve({
                name: 'EntryStoma',
            })
            window.open(newPage.href, '_blank')
        },
        //  首页
        requestNewPage() {
            let newPage = this.$router.resolve({
                name: 'EntryStoma',
            })
            window.open(newPage.href, '_blank')
        },

        setStatus(val) {

            var newcurrentStatus = this.currentStatus
            //this.currentStatus = [this.currentStatus]
            this.currentStatus = newcurrentStatus
        },
        /**
         *  分页
         * @param page number
         */
        getData(page) {
            this.pages = page;
            this.total = 0
            let status = JSON.parse(JSON.stringify(this.currentStatus))
            if (!status.length) status = [2, 20, 21]
            let self = this;
            this.$axios.post('/enterprise/condition', {
                current: page,
                size: this.pageSize,
                name: self.searchName,
                nature: self.searchType,
                statuses: status
            }).then(response => {
                console.log(page)
                self.tableData = response.data.data.records;
                self.total = response.data.data.total;
            }).catch(error => {
                console.error(error)
            })
            //获取 全部角色
            this.$axios.get('/role/page', {
                params: {
                    current: 1,
                    size: 100
                }
            }).then(function (res) {

                self.roledata = []
                self.rolevalue = []
                for (var i = 0; i < res.data.data.records.length; i++) {
                    self.roledata.push({
                        value: res.data.data.records[i].id,
                        desc: res.data.data.records[i].name,
                    });
                }
            })


        },

        //  查询
        requestQuery() {
            this.getData(1);
        },
        requestRefresh() {
            this.searchName = "";
            this.searchType = "";
            this.currentStatus = [];
            this.getData(1);
        },
        /**
         * 分页
         * @param value number
         */
        requestPages(value) {
            this.pages = value;
            //this.$axios.get('/enterprise/page/status', {
            this.getData(value);
            return
            this.$axios.post('/enterprise/condition', {
                params: {
                    current: value,
                    size: this.pageSize,
                    statuses: [2, 20, 21]
                }
            }).then(response => {
                this.tableData = response.data.data.records;
            }).catch(error => {
                console.error(error)
            })
        },

        /**
         * 查看详情
         * @param row object
         */
        requestSee(row) {
            let newPage = this.$router.resolve({
                path: '/business/' + row.id,
            })
            window.open(newPage.href, '_blank')
        },
        /**
         * 锁定功能
         * @param num  number
         * @param Id number
         * @param row object
         * @constructor
         */
        SetStatus(num, Id, row) {
            var self = this;
            let daa = {
                id: Id,
                status: num
            }
            if (num === 0) {
                this.$confirm('锁定后该账户将无法登录系统，确定要锁定该账户吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios.put('/account', daa).then(function (res) {
                        if (res.data.status === 200) {
                            self.$message({
                                showClose: true,
                                message: "锁定成功",
                                type: "success"
                            });
                            self.getData(self.page = 1);
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消锁定'
                    });
                });
            } else {
                this.$confirm('解锁后该账户可正常登录和使用系统，确认解锁吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios.put('/account', daa).then(function (res) {
                        if (res.data.status === 200) {
                            self.$message({
                                showClose: true,
                                message: "解锁成功",
                                type: "success"
                            });
                            self.getData(self.page = 1);
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消解锁'
                    });
                });
            }
        },

        /**
         * 重置密码
         * @param row object
         */
        resetPass(row) {
            var self = this;
            this.$confirm('您重置密码吗？ 默认密码：Aa#123')
                .then(_ => {
                    this.$axios.put('/account/password/reset', {
                        newPassword: 'Aa#123',
                        username: row.account.username
                    }).then(response => {
                        if (response.status === 200) {
                            self.$message({
                                showClose: true,
                                message: '重置密码成功',
                                type: "success"
                            });
                        }
                    });
                }).catch(_ => {
                self.$message({
                    showClose: true,
                    message: "已取消",
                    type: "success"
                });
            });
        },

        /**
         * 角色编辑
         * @param row object
         */
        handleEdit(row) {
            var self = this;
            this.usernum = true;
            this.form.id = row.account.id
            this.form.status = row.account.status
            this.rolevalue = [];
            this.title = row.account.username + '修改'
            this.$axios.get('/account?id=' + row.account.id).then(function (res) {
                for (var i = 0; i < res.data.data.roles.length; i++) {
                    self.rolevalue.push(res.data.data.roles[i].id);
                }
            })

        },
        //确定修改角色
        submitForm() {
            var self = this;
            this.form.roleIds = this.rolevalue
            this.$axios.put('/account', this.form).then(function (res) {
                if (res.data.status === 200) {
                    self.$message({
                        showClose: true,
                        message: "修改成功",
                        type: "success"
                    });
                    self.usernum = false
                    self.getDate();
                }
            })

        },
    }
};
</script>

<style lang="less" scoped>
.headtit {
    background: #1090e7;
    height: 50px;
    text-align: left;
    line-height: 50px;
    padding: 0 0 0 16px;
    color: #fff;
    font-size: 16px;
}

.buttbut {
    overflow: hidden;
    width: 500px;
    margin: 0 auto;
}

.basicCoxs {
    h2 {
        font-size: 16px;
        margin: 0;
        height: 35px;
        text-align: left;
    }

    .cailxx {
        span {
            margin: 10px 0;
        }
    }

    .fujianx {
        button {
            width: 45%;
            float: left;
            background: #fff;
            margin: 5px;
            text-align: left;
            cursor: pointer;
        }
    }

    span {
        background: #f3f3f3;
        display: block;
        height: 35px;
        text-align: left;
        padding: 0 16px;
        line-height: 35px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        float: left;
        margin: 1px;
        width: 45%;
        min-width: 400px;
    }
}

#pagination {
    margin: 32px 0 16px 0;
}
</style>